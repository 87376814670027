<template>
  <v-container>
    <v-card class="pa-4">
      <div class="text-center mb-2 secondary--text">
        {{ type }} {{ n }} Information
      </div>
      <v-dialog
        ref="lossDateDialog"
        v-model="lossDateDialog"
        :return-value.sync="lossDate"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <validation-provider
            v-slot="{ errors }"
            name="Date of Loss"
            rules="required"
          >
            <v-text-field
              v-model="lossDate"
              v-mask="'####-##-##'"
              outlined
              label="Date of Loss YYYY-MM-DD"
              :error-messages="errors"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="recordLoss"
            />
          </validation-provider>
        </template>
        <v-date-picker
          v-model="lossDate"
          scrollable
          :min="min"
          :max="max"
          color="secondary"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              lossDateDialog = false;
            "
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="
              $refs.lossDateDialog.save(lossDate);
            "
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <validation-provider
        v-slot="{ errors }"
        name="Amount"
        rules="required|numeric"
      >
        <v-text-field
          v-model.number="amt"
          v-mask="'#########'"
          outlined
          label="Amount paid"
          :error-messages="errors"
          @blur="recordLoss"
          @click="
            clickedFieldGlobal(
              clickFieldValues[1],
              sequenceNumbers[1],
              surveyType
            )
          "
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Claim Status"
        rules="required"
      >
        <v-select
          v-model="claimStatusCd"
          :items="lossCodes"
          outlined
          label="Claim Status"
          item-text="txt"
          item-value="value"
          :error-messages="errors"
          @change="recordLoss"
          @click="
            clickedFieldGlobal(
              clickFieldValues[2],
              sequenceNumbers[2],
              surveyType
            )
          "
        />
      </validation-provider>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Loss',
    props: {
      min: { type: String, default: '' },
      max: { type: String, default: '' },
      type: { type: String, default: '' },
      n: { type: Number, default: 0 },
      id: { type: String, default: '' },
      locationRef: { type: String, default: '' },
      lossCause: { type: String, default: '' },
      lossCodes: { type: Array, default: () => [] },
      clickFieldValues: { type: Array, default: () => [] },
      sequenceNumbers: { type: Array, default: () => [] },
      surveyType: { type: String, default: '' }
    },
    data () {
      return {
        lossDateDialog: false,
        lossDate: '',
        amt: '',
        claimStatusCd: ''
      }
    },
    methods: {
      recordLoss () {
        console.log(this.claimStatusCd)
        if (this.lossDate && this.amt && this.claimStatusCd) {
          let loss = {
            id: this.id,
            locationRef: this.locationRef,
            lossDt: this.lossDate,
            totalPaidAmt: {
              amt: Number(this.amt).toFixed(2)
            },
            lossCauseCd: this.lossCause,
            claimStatusCd: this.claimStatusCd
          }
          this.$emit('record-loss', loss)
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
